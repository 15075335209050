<template>
  <section>
    <div id="upload-status-modal" class="uk-flex-top upload-status-modal uk-modal-container" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
          <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
          <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center">Upload Status</h2>
          </div>
          <section class="uk-padding uk-padding-remove-bottom">
            <p class="uk-margin-remove">Upload Successful: <b>{{ data.success }} Users</b></p>
            <div class="uk-flex uk-flex-between uk-flex-middle">
              <p class="uk-margin-remove">Upload Failed: <b>{{ data.failed }} Users</b></p>
              <button
                class="uk-button uk-button-primary uk-text-bold uk-border-rounded uk-text-nowrap"
                @click="downloadFailedData"
              >
                <img :src="`${images}/shift-download-white.svg`" alt="" width="25" uk-img class="photo">
                Download
              </button>
            </div>
          </section>
          <section class="uk-card uk-card-default uk-padding">
            <div class="uk-overflow-auto">
              <table class="uk-table uk-table-medium uk-table-striped">
                <thead>
                  <tr>
                    <th class="uk-table-expand">Office</th>
                    <th class="uk-table-expand">Job Role</th>
                    <th class="uk-table-shrink">Job ID</th>
                    <th class="uk-table-shrink">Name</th>
                    <th class="uk-table-expand">Email</th>
                    <th class="uk-table-expand">Status</th>
                  </tr>
                </thead>
                <tbody v-if="data.failed > 0">
                  <tr v-for="(user, index) in data.failedUser" :key="index">
                    <td>{{ user.user['Office'] }}</td>
                    <td>{{ user.user['Role'] }}</td>
                    <td>{{ user.user['Job ID'] }}</td>
                    <td>{{ user.user['Name*'] }}</td>
                    <td>{{ user.user['Email*'] }}</td>
                    <td>{{ user.notes }}</td>
                  </tr>
                </tbody>
                <empty-table v-else :colspan="6" />
              </table>
            </div>
            <div class="uk-modal-header" />
            <section class="uk-flex uk-flex-middle uk-flex-right">
              <button class="uk-button uk-button-primary uk-margin-top" type="button" @click="hideModal">Close</button>
            </section>
          </section>
      </div>
  </div>
  </section>
</template>

<script>
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { PREFIX_IMAGE } from "@/utils/constant";
import { excelDownloader } from '@/utils/helper';

export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        officeName: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
        };
    },
    components: {
        EmptyTable,
    },
    mounted() {
        window.UIkit.modal('#upload-status-modal').show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal('#upload-status-modal').$destroy(true);
            this.$emit("hideModal");
        },
        downloadFailedData() {
            const reConstruct = this.data.failedUser.map(d => d.user);
            excelDownloader(reConstruct, `Full Migration_${this.officeName}.xlsx`);
        }
    }
};
</script>

<style scoped>
  thead {
    background: #0ABAB5;
  }

  th {
    color: #FFFFFF;
  }

  td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
</style>
